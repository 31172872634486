@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&display=swap);
html,
body {
  min-height: 100vh;
}

@font-face {
  font-display: swap;
  font-family: 'futurastdbook';
  src: local('futurastdbook'),
    url(/static/media/FuturaStd-Book.5f83b004.otf) format('truetype');
}

