html,
body {
  min-height: 100vh;
}

@font-face {
  font-display: swap;
  font-family: 'futurastdbook';
  src: local('futurastdbook'),
    url(./assets/fonts/futuraStdBook/FuturaStd-Book.otf) format('truetype');
}

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&display=swap');
